@font-face {
    font-family: 'Georgia';
    font-style: normal;
    font-weight: 100 900;
    src: url(./fonts/georgia.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100 900;
    src: url(./fonts/Montserrat-VariableFont_wght.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
    --blue-dark: #004466;
    --blue-medium: #0088CC;
    --purple-dark: #220066;
    --background: white;
    /* --blue-dark: #99DDFF;
    --blue-medium: #E6F7FF;
    --purple-dark: #EEE6FF;
    --background: #29363D; */
}

a {
    text-decoration: none;
}

.link {
    color: var(--purple-dark);
}

.clickable {
    cursor: pointer;
}

.outer-outer {
    margin: 0px;
}
.home-outer {
    background-color: var(--background);
    margin: 0px;
}

.home-header {
    display: flex;
    justify-content: space-between;
    background-color: var(--background);
    top: 0;
    /* position: sticky; */
    z-index:   1000;
}

.home-header-left {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: 30px;
    margin-right: 0px;
}

.home-header-cells {
    display: flex;
}

.hidden {
    display: none;
}

.home-header-cells-wrapper {
    display: block;
}

.header-hamburger {
    display: none;
    font-size: 2em;
    padding-right: 15px;
    cursor: pointer;
}

.header-hamburger-wrapper {
    display: flex;
}

.home-header-cell {
    text-align: center;
    font-family: Georgia;
    font-size: 1.1em;
    color: var(--blue-dark);
    cursor: pointer;
    width: 140px;
}

.header-cal {
    position: absolute;
    top: 100%;
    right: 0;
}

@media(max-width: 1000px) {
    .home-header-left {
        display: block;
        position: relative;
    }

    .home-header-cells-wrapper {
        display: none;
    }

    .home-header-cells {
        display: block;
        position: absolute;
        top: 100%;
        z-index: 1000;
        background-color: var(--background);
        margin-left: 50px;
    }

    .header-hamburger {
        display: block;
    }

    .home-header-cell {
        padding: 10px;
        border: 1px solid black;
    }
}

.home-header-right {
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: 30px;
    margin-left: 30px;
}

@media(max-width: 600px) {
    .home-header-right {
        display: none;
    }
}

.home-footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: left;
    align-items: top;
    margin: 30px;
}

.home-footer-link {
    color: var(--blue-dark);
    font-family: Montserrat;
    font-weight: 400;
    font-size: 1.1em;
    padding: 3px;
}

.bottom-buffer {
    height: 50px;
}

.horizontal-buffer {
    background-color: var(--blue-dark);
    margin-top: 30px;
    height: 10px;
}

.home-button {
    color: var(--background);
    background-color: var(--blue-medium);
    height: fit-content;
    border-radius: 10px;
    padding: 15px 25px 15px 25px;
    cursor: pointer;
    margin: 10px auto;
    display: inline-block;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 1.1em;
    text-align: center;
}

.logo-cell {
    margin-right: 30px;
}

.home-limiter {
    max-width: 1600px;
    margin: auto;
}

.outer-outer {
    margin: 0px;
}

.hero-title {
    text-align: center;
    max-width: 650px;
    margin: auto;
    padding: 0px 30px;
    margin-top: 50px;
    margin-bottom: 40px;
    font-family: Montserrat;
    font-size: 3.2em;
    font-weight: 700;
    color: var(--blue-medium);
    /* border: 1px solid red; */
}

.hero-subtitle {
    text-align: center;
    max-width: 650px;
    padding: 0px 30px;
    margin: auto;
    margin-bottom: 50px;
    font-family: Montserrat;
    font-size: 2em;
    font-weight: 400;
    color: var(--purple-dark);
}

.consult-call {
    text-align: center;
    padding: 0px 30px;
    font-family: Montserrat;
    font-size: 1.2em;
    color: var(--blue-dark);
    margin-top: 20px;
    cursor: pointer;
}

.divider {
    margin: 70px auto 70px auto;
    text-align: center;
}

.section-outer {
    display: flex;
    justify-content: space-around;
}

.two-column {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-wrap: wrap;
}

.two-column-inner {
    width: 400px;
    padding: 0px 30px;
}

@media(max-width: 960px) {
    .two-column-inner {
        padding: 0px;
        margin: auto;
    }
}
.column-title {
    font-family: Georgia;
    font-size: 2em;
    color: var(--blue-medium);
    margin-bottom: 10px;
}

.column-title-padding {
    height: 36px;
    margin-bottom: 10px;
}

.column-body {
    font-family: Montserrat;
    font-size: 1.2em;
    color: var(--blue-dark);
    line-height: 1.5;
    margin-bottom: 15px;
}

.centered-text {
    /* font-family: Montserrat; */
    /* font-size: 1.2em; */
    /* color: var(--blue-dark); */
    /* line-height: 1.5; */
    max-width: 400px;
    margin: auto;
    margin-bottom: 15px;
    text-align: center;
}

.column-subtitle {
    font-family: Montserrat;
    font-size: 1.5em;
    color: var(--blue-medium);
    margin-bottom: 10px;
}

.column-image-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.column-image {
    width: auto;
    height: auto;
    max-width: 300px;
    max-height: 300px;
    box-shadow: 3px 3px 6px grey;
}

.portrait-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 30px;
    width: 400px;
}

ul {
    margin-top: 0px;
}

li {
    margin-bottom: 8px;
}

li.wide {
    margin-bottom: 24px;
}
