.modal-outer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-inner {
    padding: 10px 30px 10px 30px;
    background-color: #ffffff;
    max-height: 80%;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.modal-top {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-top: 20px;
}

.form-outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    /* height: 460px; */
}

.form-row {
    display: flex;
    gap: 20px;
}

.form-box {
    margin: 10px 0px;
}

.form-field {
    font-family: Montserrat;
    font-size: 1em;
    color: var(--blue-dark);
    padding: 10px;
    border-radius: 5px;
    width: 260px;
    border: 1px solid black;
    box-sizing:border-box;
}

.text-box {
    width: 540px;
    box-sizing:border-box;
}

.form-label {
    font-family: Montserrat;
    font-size: 1em;
    color: var(--blue-dark);
    margin-bottom: 5px;
}

.submit-outer {
    width: 100%;
    text-align: right;
    margin-right: 0px;
    margin-bottom: 20px;
}

.form-final-outer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
}

.form-final {
    font-family: Montserrat;
    font-size: 1.2em;
    color: #004466;
    text-align: center;
    width: 500px;
    margin-bottom: 50px;
}

.loader {
    width: 50px;
    aspect-ratio: 1;
    --_c:no-repeat radial-gradient(farthest-side,#004466 92%,#0000);
    background:
      var(--_c) top,
      var(--_c) left,
      var(--_c) right,
      var(--_c) bottom;
    background-size: 12px 12px;
    animation: l7 1s infinite;
    margin-bottom: 60px;
}
@keyframes l7 {to{transform: rotate(.5turn)}}

.form-fail {
    background-color: red;
    font-family: Montserrat;
    font-size: 1em;
    color: white;
    padding: 20px;
    width: 500px;
    margin: auto;
    text-align: center;
}

.form-close {
    color: black;
    text-align: right;
    cursor: pointer;

}