@font-face {
    font-family: 'Georgia';
    font-style: normal;
    font-weight: 100 900;
    src: url(./fonts/georgia.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100 900;
    src: url(./fonts/Montserrat-VariableFont_wght.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
    --blue-dark: #004466;
    --blue-medium: #0088CC;
    --blue-light: #99DDFF;
    --blue-white: #E6F7FF;
    --purple-dark: #220066;
    --grey-medium: #526D7A;
    --grey-light: #C2CFD5;
    --grey-white: #F0F3F5;
    --background: #ffffff;
}

a, a:visited, a:hover, a:active {
    color: inherit;
}

.hub-outer {
    display: flex;
    gap: 40px;
    max-width: 1100px;
    margin: auto;
    /* height: 100vh;
    overflow: auto; */
}

.hub-contents {
    max-width: 500px;
}

.hub-toc {
    /* position: sticky;
    top: 0px; */
    width: 280px;
    font-family: Montserrat;
    font-size: 1.0em;
    color: var(--blue-dark);
    border-right: 1px solid black;
    cursor: pointer;
}

.hub-toc-dropdown {
    display: none;
    padding: 30px;
    padding-top: 0px;
    font-family: Montserrat;
    font-size: 1.0em;
    color: var(--blue-dark);
}

@media(max-width: 820px) {
    .hub-toc {
        display: none;
    }

    .hub-contents {
        margin: auto;
    }

    .hub-outer {
        padding: 0px 30px;
    }

    .hub-toc-dropdown {
        display: block;
    }
}

.hub-toc-section {
    padding: 10px 0px;
}

.hub-toc-top {
    padding: 10px;
    padding-left: 0px;
}

.hub-toc-chapter {
    padding: 10px;
    padding-left: 40px;
}

.hub-toc-none:hover {
    background-color: var(--blue-white);
}

.hub-toc-active {
    background-color: var(--blue-light);
}

.hub-page-title-1 {
    font-family: Georgia;
    font-size: 1.8em;
    font-weight: 500;
    color: var(--blue-medium);
    margin-bottom: 20px;
    text-align: center;
}

.hub-page-title-2 {
    font-family: Georgia;
    font-size: 1.4em;
    font-weight: 500;
    color: var(--blue-medium);
    margin-bottom: 15px;
}

.hub-page-text {
    font-family: Montserrat;
    font-size: 1.0em;
    color: var(--blue-dark);
    margin-bottom: 25px;
    line-height: 1.5;
}

.site-outer {
    margin: 10px;
    padding: 20px 20px 5px 20px;
    border: 1px solid var(--grey-medium);
    border-radius: 10px;
    box-shadow: 3px 3px 6px var(--grey-light);
}

.site-name {
    font-family: Georgia;
    font-size: 1.4em;
    color: var(--purple-dark);
    font-weight: 500;
    margin-bottom: 15px;
}


.examples-drop-down-wrapper {
    margin: 20px 0px;
}

.hub-main-outer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-width: 980px;
    margin: 50px auto;
}

.hub-main-section {
    border: 1px solid var(--grey-medium);
    border-radius: 10px;
    box-shadow: 3px 3px 6px var(--grey-light);
    padding: 20px;
    width: 250px;
}

.hub-main-section-title {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 1.2em;
    color: var(--blue-dark);
    margin-bottom: 15px;
}

.hub-main-chapter {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 1em;
    color: var(--blue-dark);
    margin-left: 20px;
    margin-bottom: 15px;
}

.subscribe-outer {
    max-width: 600px;
    padding: 0px 20px;
    margin: auto;
    margin-top: 40px;
    text-align: center;
}

.subscribe-container {
    max-width: 480px;
}

.subscribe-container iframe {
    width: 100%;
}
.hub-callout-box {
    border: 1px solid black;
    padding: 15px 20px 0px 20px;
    margin: 5px;
    margin-bottom: 40px;
    background-color: var(--grey-white);
}